<template>
	<!-- 新增文章专栏 -->
	<div class="container">
		<div class="policyCompilationTitle">电子政策汇编系统</div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
			<el-breadcrumb-item :to="{ path: '/' }"><el-button type="primary" size="mini">返回</el-button></el-breadcrumb-item>
			<el-breadcrumb-item>
				<el-button type="text" size="mini" style="color: #555;font-size: 14px;">{{title}}</el-button>
			</el-breadcrumb-item>
		</el-breadcrumb>

		<el-form label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm">
			<el-form-item label="姓名" prop="name">
				<el-input style="width: 300px;" placeholder="请输入姓名" v-model="ruleForm.name" clearable></el-input>
			</el-form-item>

			<el-form-item label="手机号码" prop="mobile">
				<el-input style="width: 300px;" placeholder="请输入手机号码" v-model="ruleForm.mobile" clearable></el-input>
			</el-form-item>

			<el-form-item label="邮箱" prop="email">
				<el-input style="width: 300px;" placeholder="请输入邮箱" v-model="ruleForm.email" clearable></el-input>
			</el-form-item>

			<el-form-item label="密码" prop="password" v-if="!memberID" required>
				<el-input style="width: 300px;" type="password" placeholder="请输入密码" v-model="ruleForm.password" clearable></el-input>
			</el-form-item>

			<el-form-item label="重复密码" prop="repassword" v-if="!memberID" required>
				<el-input style="width: 300px;" type="password" placeholder="请再输入一次密码" v-model="ruleForm.repassword" clearable></el-input>
			</el-form-item>

			<el-form-item label="品牌" prop="brand">
				<el-input style="width: 300px;" placeholder="请输入品牌" v-model="ruleForm.brand" clearable></el-input>
			</el-form-item>

			<el-form-item label="公司名称" prop="company">
				<el-input style="width: 300px;" placeholder="请输入公司名称" v-model="ruleForm.company" clearable></el-input>
			</el-form-item>

			<el-form-item label="身份角色	" prop="roles">
				<el-select v-model="value" multiple placeholder="请选择身份角色">
					<el-option v-for="item in ruleForm.roles" :key="item.name" :label="item.title" :value="item.name">
					</el-option>
				</el-select>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
			</el-form-item>
		</el-form>
	</div>

</template>
<script>
	export default {
		data() {
			var validatemobile = (rule, val, callback) => {
				if (!val) {
					callback(new Error('手机号码不能为空！'));
				} else {
					const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
					if (reg.test(val)) {
						callback();
					} else {
						return callback(new Error('请输入正确的手机号'));
					}
				}
			};
			var validatorPassword = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.repassword !== '') {
						this.$refs.ruleForm.validateField('repassword');
					}
					callback();
				}
			};
			var validatorRepassword = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};

			return {
				ruleForm: {
					name: '',
					mobile: '',
					email: '',
					password: '',
					repassword: '',
					brand: '',
					company: '',
					roles: [],
					id: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						trigger: 'blur',
						validator: validatemobile
					}],
					email: [{
							required: true,
							message: '请输入邮箱地址',
							trigger: 'blur'
						},
						{
							type: 'email',
							message: '请输入正确的邮箱地址',
							trigger: ['blur']
						}
					],
					password: [{
						validator: validatorPassword,
						trigger: 'blur'
					}],
					repassword: [{
						validator: validatorRepassword,
						trigger: 'blur'
					}]
				},
				origin_id: '',
				value: '',
				title: '',
				memberID: ''
			}
		},
		mounted() {
			this.origin_id = sessionStorage.organizationId;
			this.getJiaose();
			if (this.$route.query.id) {
				this.memberID = this.$route.query.id
				this.title = '编辑成员';
				this.$http.get(this.URL.adminUrl.managersList + this.origin_id + '/managers/' + this.memberID).then(res => {
					const result = res.data.data;
					this.ruleForm.name = result.name;
					this.ruleForm.mobile = result.mobile;
					this.ruleForm.email = result.email;
					this.ruleForm.password = result.user_password;
					this.ruleForm.repassword = result.re_user_password;
					this.ruleForm.brand = result.brand;
					this.ruleForm.company = result.company;
					result.role_names.forEach(item => {
						this.value.push(item.name)
					})
				})
			} else {
				this.title = '添加成员';
			}
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post(this.URL.adminUrl.managersList + this.origin_id + '/managers/' + this.memberID, {
							name: this.ruleForm.name,
							mobile: this.memberID ? '' : this.ruleForm.mobile,
							email: this.ruleForm.email,
							user_password: this.ruleForm.password,
							re_user_password: this.ruleForm.repassword,
							brand: this.ruleForm.brand,
							company: this.ruleForm.company,
							roles: this.value,
						}).then((res) => {
							this.$router.push('/');
						})
					} else {
						return false;
					}
				});
			},
			getJiaose() {
				this.$http.get(this.URL.adminUrl.managersList + this.origin_id + '/roles').then((res) => {
					this.ruleForm.roles = res.data.data
				})
			}
		}
	}
</script>


<style scoped>
	.navbar {
		margin-bottom: 30px;
		padding: 15px;
		border-bottom: 1px solid #ccc
	}
</style>
